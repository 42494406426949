<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='2' >

    <div class="w">
      <!-- banner开始 -->
      <div class="w">
        <banner class="w-full" title="PRODUCT" bgImg="https://s3.moke.cloud/shuangmei/10d44775-f20a-43db-a1e9-4315dc9f41f2.jpg" ></banner>
      </div>
      <!-- banner结束 -->

      <!-- 招牌产品开始 -->
      <div class="feature w row p-0 m-0 bg-white" >
        <steps class="container-left col-md-1 p-0 m-0" num='01' title='招牌产品' color='#CA4938' ></steps>
        <div class="feature-swiper col-md-11 p-0 m-0">
          <productSwiper v-if="featureList.length" :list='featureList' ></productSwiper>
        </div>
      </div>
      <!-- 招牌产品结束 -->

      <!--  新品 开始 -->
      <div class="news w row p-0 m-0" >
        <steps class="container-left col-md-1 p-0 m-0" num='02' title='新品上市' color='#CA4938' ></steps>
        <div class="news-center col-md-11 px-0 py-60 m-0">
          <div class="news-center-item w-full" v-for="(item,index) in newsList" :key="index">
            <product :item='item' ></product>
          </div>
        </div>
      </div>
      <!-- 新品 结束 -->

      <!-- 其他 开始 -->
      <div class="rests w row p-0 m-0 bg-white" >
        <steps class="container-left col-md-1 p-0 m-0" num='03' title='其他商品' color='#CA4938' ></steps>
        <div class="rests-center col-md-11 px-0 pt-0 pb-60 m-0">
          <div class="rests-center-item w-full" v-for="(item,index) in restsList" :key="index">
            <headlines class="headlines live-XQ" :title='item.name' english="PRODUCT" color="#1E456C" align='center' ></headlines>
            <div class="rests-introduce text-blue wow fadeInUp">{{item.introduce}}</div>
            <div class="rests-img w-full" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}" ></div>
          </div>
        </div>
      </div>
      <!-- 其他 结束 -->

    </div>
  </Containers>
</template>

<script>
import { WOW } from 'wowjs'

import banner from '@/components/banner'
import steps from '@/components/steps'
import productSwiper from '@/components/productSwiper'
import product from '@/components/product'

export default {
  metaInfo: {
    title: '产品在线_双美豆沙牛乳官方网站',
    meta: [
      {
        name: 'keywords',
        content: '双美豆沙牛乳产品在线_双美豆沙牛乳官网'
      },
      {
        name: 'description',
        content: '本页面为您提供双美豆沙牛乳产品展示，包含双美豆沙牛乳加盟店内的热销产品、双美豆沙牛乳加盟店内主打产品等，方便您更加全面了解双美豆沙牛乳加盟店内产品信息。'
      }
    ]
  },
  data () {
    return {
      status: 'success',
      featureList: [], // 特色列表
      newsList: [], // 新品列表
      restsList: [], // 其他列表
      text: '', // 需要实时压缩的文本

    }
  },
  mounted () {
    this.getInit()
  },
  components: {
    banner,
    steps,
    productSwiper,
    product,
  },
  computed: {
  },
  methods: {
    funEnd () {
      // setTimeout(() => {
      //   this.status = 'success'
      //   this.$nextTick(() => {
      //     // 在dom渲染完后,再执行动画
      //     const wow = new WOW({
      //       live: false
      //     })
      //     wow.init()
      //   })
      // }, 300)
    },
    async getInit () {
      try {
        // this.status = 'waiting'
        this.text = ''
        await this.getFeature()
        await this.getNews()
        await this.getRests()
        await this.getFont(this.text)
        setTimeout(() => {
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false
            })
            wow.init()
          })
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
    },
    async getFeature () {
      let list = await this.$axios.get('/classes/product', {
        params: {
          order: '-weight',
          where: {
            type: 'feature'
          }
        }
      })
      this.featureList = list.results
      list.results.forEach(item => {
        this.text += item.name
      })
    },
    async getNews () {
      let list = await this.$axios.get('/classes/product', {
        params: {
          order: '-createdAt',
          where: {
            type: 'news'
          }
        }
      })
      this.newsList = list.results
      list.results.forEach(item => {
        this.text += item.name
      })
    },
    async getRests () {
      let list = await this.$axios.get('/classes/product', {
        params: {
          order: '-createdAt',
          where: {
            type: 'rests'
          }
        }
      })
      this.restsList = list.results
      list.results.forEach(item => {
        this.text += item.name
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

.container-left{
  @include hamburger {
    height: auto;
  }
}
.feature{
  height: 560px;
  margin-bottom: 823px !important;
  @include hamburger {
    height: 188px;
    margin-bottom: 300px !important;
  }
  .feature-swiper{
    height: 1320px;
    padding-top: 60px !important;
    @include hamburger {
      height: 400px;
      padding: 0px 12px !important;
    }
  }
}

.news{
  background-color: #FCFAF0;
  .news-center{
    @include hamburger {
      padding: 0px 12px !important;
    }
    .news-center-item{
      height: 850px;
      ::v-deep{
        .introduce{
          padding-top: 100px;
        }
      }
      @include hamburger {
        height: 420px;
        ::v-deep{
          .introduce{
            padding-top: 0px;
          }
        }
      }
    }
  }
}
.rests{
  .rests-center-item{
    padding-top: 110px;
    @include hamburger {
      padding: 0 12px 30px;
    }
    .rests-introduce{
      width: 90%;
      margin: 50px auto;
      @include hamburger {
        width: 100%;
        margin: 20px auto;
      }
    }
    .rests-img{
      height: 780px;
      @include hamburger {
        height: 217px;
      }
    }

  }

}

</style>
